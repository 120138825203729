/* Infocard hover */
.infoCard:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  /* blue shadow */
  box-shadow: 0 0.5rem 1rem rgba(0, 78, 70, 0.328) !important;
}

/* Infocard hover */
.infoCard:active {
  transition: all 0.3s ease-in-out;
  /* blue shadow */
  box-shadow: 0 0.5rem 1rem rgba(0, 78, 70, 0.328) !important;
}
