.customInput {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.supplierImage:hover {
  cursor: pointer !important;
}
