.mainback {
  min-height: 80vh;
  display: flex;
  padding: 10px 0;
}

.page {
  width: 100%;
}

body {
  background-color: #f1f6f4;
}
